import React from 'react';
import Modal from './modal';

export type LoginStatus = { inProgress: boolean; passwordWasIncorrect?: boolean; otherError?: string };

export default function Login({ logIn, status }: { logIn: (user: string, password: string) => void, status: LoginStatus }) {
    const inputStyle = "rounded-md border border-gray-300 p-2 disabled:opacity-40";
    const [user, setUser] = React.useState('');
    const [password, setPassword] = React.useState('');
    return (
        <Modal
            title="Mewngofnodi"
            buttons={{
                submit: {
                    label: "Mewngofnodi" + (status.inProgress ? '…' : ''),
                    onClick: () => logIn(user, password),
                    disabled: status.inProgress || !(user && password),
                    inProgress: status.inProgress
                },
                cancel: {
                    label: "Clirio",
                    onClick: () => { setUser(''); setPassword(''); },
                    disabled: status.inProgress || !(user || password)
                }
            }}
        >
            <div className="">
                <select className={inputStyle + " form-select transition ease-in-out bg-gray-50"}
                    value={user}
                    onChange={evt => setUser(evt.target.value)} disabled={status.inProgress}
                >
                    <option className="disabled:text-gray-700" value="" disabled>Dewis defnyddiwr…</option>
                    <option value="iona">Iona</option>
                    <option value="eleri">Eleri</option>
                </select>
                <input className={inputStyle + " w-full mt-2" + (status.passwordWasIncorrect ? " outline outline-4 outline-orange-300" : '')}
                    placeholder="Cyfrinair" type="password"
                    value={password} onChange={evt => setPassword(evt.target.value)} disabled={status.inProgress}
                />
                {(status.passwordWasIncorrect || status.otherError) &&
                    <div className="mt-2 rounded-md p-2 text-orange-800 bg-orange-200">
                        {status.passwordWasIncorrect ?
                            "Mae’r cyfrinair yn anghywir" :
                            status.otherError &&
                            <div>
                                Gwall wrth geisio cysylltu â’r gweinydd:
                                <div className="mt-1 mb-1 p-2 font-mono border-t border-b border-orange-800">{status.otherError}</div>
                                — rhowch gynnig arni eto
                            </div>
                        }
                    </div>
                }
            </div >
        </Modal >
    );
}
