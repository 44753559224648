import Progress from './progress';

export type TreeNodeSpec = { k: string, label: string; children?: TreeNodeSpec[]; isExpanded?: boolean, isLoading?: boolean }
type OnClick = (key: string) => void;

function TreeNode({ k, label, children, isExpanded, isLoading, onClick }: TreeNodeSpec & { onClick: (key: string) => void }) {
    const indicator =
        (isLoading && <div className="fill-accent-900 text-gray-400"><Progress /></div>) ||
        (children &&
            <svg role="status" className={"inline " + (isExpanded ? '' : "-rotate-90")} viewBox="0 0 100 101" xmlns="http://www.w3.org/2000/svg">
                <polygon points="0,0 100,0 50,100" fill="currentColor" />
            </svg>);
    return (
        <li onClick={() => onClick(k)} className="flex flex-col items-baseline">
            <div className="flex">
                <div className="w-5">
                    {indicator && <div className={(isLoading ? "w-4 h-4" : "w-3 h-3") + " mr-2"}>{indicator}</div>}
                </div>
                {label}
            </div>
            <ul className="ml-4">
                {children && children.map(node => <TreeNode key={node.k} onClick={onClick} {...node} />)}
            </ul>
        </li >
    );
}

export default function Tree({ nodes, onClick }: { nodes: TreeNodeSpec[], onClick: OnClick }) {
    return (
        <ul className="space-y-4">
            {nodes.map(node => <TreeNode key={node.k} onClick={onClick} {...node} />)}
        </ul>
    );
}
