import _set from 'lodash.set';
import React from 'react';
import { createClient, FileStat, WebDAVClient } from 'webdav/web';
import './App.css';
import { LoginStatus } from './components/login';
import Login from './components/login';
import Tree, { TreeNodeSpec } from './components/tree';

const basePath = "/masteruser.ioel.co.uk/files";
const adminPath = basePath + "/ioel-admin";

type TreeAction = { kind: 'closePages' | 'loadingPages' | 'openPages' | 'pagesError'; children?: TreeNodeSpec[], error?: any }

function treeReducer(state: TreeNodeSpec[], action: TreeAction) {
    switch (action.kind) {
        case 'closePages':
            console.log("Closing pages");
            return [{ ...state[0], isLoading: false, isExpanded: false, children: [] }, ...state.slice(1)];
        case 'loadingPages':
            console.log("Loading pages");
            return _set(state, [0, 'isLoading'], true);
        case 'openPages':
            console.log("Opening pages", action.children);
            return [{ ...state[0], isLoading: false, isExpanded: true, children: action.children || [] }, ...state.slice(1)];
        case 'pagesError':
            alert("Methu llwytho'r tudalennau"); // TODO
            console.error(action.error);
            return [{ ...state[0], isLoading: false, isExpanded: false, children: [] }, ...state.slice(1)];
    }
}

export default function App() {
    const [loginStatus, setLoginStatus] = React.useState({ inProgress: false } as LoginStatus);
    const [user, setUser] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [client, setClient] = React.useState(undefined as undefined | WebDAVClient);
    const [tree, treeDispatch] = React.useReducer(treeReducer,
        [
            { k: 'pages', label: "Tudalennau", children: [] },
            { k: 'products', label: "Cynnyrch" }
        ]
    );
    function logIn(usr: string, pass: string) {
        setLoginStatus({ inProgress: true });
        const client = createClient("https://ioel.steffan.org.uk", {
            username: usr, password: pass
        });
        client.getDirectoryContents("/").then(_ => {
            setLoginStatus({ inProgress: false });
            setClient(client);
            setUser(usr);
            setPassword(pass);
        }).catch(err => {
            const unauthorized = 'status' in err && err.status === 401;
            setLoginStatus({
                inProgress: false,
                passwordWasIncorrect: unauthorized,
                otherError: ('response' in err && err.response && 'statusText' in err.response && err.response.statusText) || err.toString()
            });
        });
    }
    function onItemClick(k: string) {
        if (k.startsWith('pages')) {
            const pages: TreeNodeSpec = tree[0];
            if (pages.isExpanded) treeDispatch({ kind: 'closePages' });
            else if (!pages.isLoading) {
                treeDispatch({ kind: 'loadingPages' });
                client?.getDirectoryContents(adminPath + "/pages").then(fileStats => {
                    treeDispatch({
                        kind: 'openPages',
                        children: (fileStats as FileStat[]).filter(f => f.type === 'directory').map(f => ({ k: 'pages/' + f.basename, label: f.basename }))
                    });
                }).catch(err => {
                    treeDispatch({ kind: 'pagesError', error: err })
                });
            }
        }
    }
    return (
        <div className="h-screen">
            {!(user && password) && <Login logIn={logIn} status={loginStatus} />
            }
            <div className="flex h-full">
                <div className="flex flex-col w-48 p-6 text-gray-800 bg-accent-100 border-r border-gray-300">
                    <Tree nodes={tree} onClick={onItemClick} />
                </div>
                <div className="flex-2"></div>
            </div>
        </div >
    );
}

