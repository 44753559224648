import React from 'react';

import Progress from './progress';

type ButtonSpec = { label: string, onClick: () => void, disabled?: boolean, inProgress?: boolean }
type ButtonSpecs = { submit: ButtonSpec; cancel?: ButtonSpec }

function Button({ className, label, onClick, disabled, inProgress }: { className: string } & ButtonSpec) {
    return (
        <button type="button"
            className={"w-full inline-flex justify-center rounded-md border shadow-sm px-4 py-2 text-base font-medium sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-30 "
                + className}
            onClick={onClick} disabled={disabled}
        >
            {inProgress && <div className="mr-2 w-6 h-6 fill-accent-600 text-gray-200 dark:text-gray-600"><Progress /></div>}
            {label}
        </button>
    );
}

export default function Modal({ title, children, buttons }: { title: string, children: React.ReactNode, buttons: ButtonSpecs }) {
    return (
        <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div className="bg-white px-4 pt-5 pb-4">
                        <div className="sm:flex sm:items-start">
                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">{title}</h3>
                                <div className="mt-2">
                                    {children}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <Button className="border-transparent text-white bg-accent-600 hover:bg-accent-700" {...buttons.submit} />
                        {buttons.cancel && <Button className="mt-3 border-gray-300 bg-white text-gray-700 hover:bg-gray-50 sm:mt-0" {...buttons.cancel} />}
                    </div>
                </div>
            </div>
        </div >
    );
}
